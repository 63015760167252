import { Tooltip } from 'antd';
import React from 'react';
import './style.css';


const CardInfo = ({ title }) => {
  return (
    <Tooltip title={title}>
      <h4 className="ellipsis-text">{title}</h4>
    </Tooltip>
  );
};

export default CardInfo;
